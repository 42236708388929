@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Castoro+Titling&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Castoro+Titling&family=Figtree:ital,wght@0,300..900;1,300..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Figtree:ital,wght@0,300..900;1,300..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Figtree:ital,wght@0,300..900;1,300..900&family=Work+Sans:ital,wght@0,100..900;1,100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Figtree:ital,wght@0,300..900;1,300..900&family=Inter:wght@100..900&family=Work+Sans:ital,wght@0,100..900;1,100..900&display=swap');

/* Enable smooth scrolling for the entire page */
html {
  scroll-behavior: smooth;
}
inputboxDate
.work-sans {
  font-family: "Work Sans", sans-serif;
  font-optical-sizing: auto;
}

.figtree {
  font-family: "Figtree", sans-serif;
  font-optical-sizing: auto;
}

.felix {
  font-family: "Castoro Titling";
  font-weight: 400;
  font-style: normal;
}

.figtree {
  font-family: "Figtree", sans-serif;
  font-optical-sizing: auto;
}

.parallex {
  background-image: url('../public/assets/parallex.png');
  background-repeat: no-repeat;
  background-size: cover;
}
@media screen and (max-width: 430px) {
  .parallex {
    background-image: url('../public/assets/parallex.png');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 30% 40%; 
  }
  
}
.inter {
  font-family: "Inter", sans-serif;
  font-optical-sizing: auto;
}

body {
  margin: 0;
  font-family: "Figtree", sans-serif;
  font-optical-sizing: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.rtl{
  direction: rtl;
}

@keyframes logoRotateY {
  from {
    transform: rotateY(0deg);
  }

  to {
    transform: rotateY(360deg);
  }
}

.animate-logoRotateY {
  animation: logoRotateY 5s linear infinite;
}

@keyframes slideTop {
  0% {
    transform: translateY(-50%);
    opacity: 0;
  }

  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes slideLeftRight {
  0% {
    transform: translateX(-50%);
  }

  50% {
    transform: translateX(50%);
  }

  100% {
    transform: translateX(-50%);
  }
}
/* scroll  */
.scroll{
  overflow-y: scroll;
  scrollbar-width: none;
  -ms-overflow-style: none;
}
.scroll::-webkit-scrollbar {
  display: none; /* Hide scrollbar in Webkit (Chrome, Safari, etc.) */
}
.scroll-container {
  
  -ms-overflow-style: none; /* Hide scrollbar in IE and Edge */
  scrollbar-width: none; /* Hide scrollbar in Firefox */
}

.scroll-container::-webkit-scrollbar {
  display: none; /* Hide scrollbar in Webkit (Chrome, Safari, etc.) */
}

.animate-slideTop {
  animation: slideTop 1s forwards;
}

.animate-slideLeftRight {
  animation: slideLeftRight 2s infinite;
}

.feed {
  position: fixed;
}



.slick-slide {
  transition: transform 0.3s ease;
}

.slick-center .slide {
  position: relative;
  left: 10%;
  transform: scale(1.1);
}

.slick-slide:not(.slick-center) .slide {
  position: relative;
  left: 10%;
  transform: scale(0.8);
}


.slick-slider {
  margin: 0 auto;
}

/* @keyframes flow {
  0% {
    transform: translateX(0%);
    
  }
  100% {
    transform: translateX(-100%);
     
  }
  0%{
    transform: translateX(0%);
  }
}
.anime {
  animation: flow infinite 4s linear;
  overflow-x: hidden;
} */
@keyframes slide {
  from {
    transform: translateX(0);
  }

  to {
    transform: translateX(-70%);
  }
}

.logos {
  overflow: hidden;
  padding: 20px 0;
  /* background: rgb(231, 15, 15); */
  white-space: nowrap;
  position: relative;
  /* background-size: c; */
}

.logos:before,
.logos:after {
  position: absolute;
  top: 0;
  width: 250px;
  height: 100%;
  content: "";
  z-index: 2;
}

.logos:before {
  left: 0;
  /* background: linear-gradient(to left, rgba(255, 255, 255, 0), rgb(116, 34, 34)); */
}

.logos:after {
  right: 0;
  /* background: linear-gradient(to right, rgba(255, 255, 255, 0), rgb(185, 23, 23)); */
}

/* .logos:hover .logos-slide {
  animation-play-state: paused;
} */

.logos-slide {
  /* display: flex; */
  animation: 20s slide infinite linear;
  animation-play-state: running;
}

.logos-slide:hover {
  animation-play-state: paused;
}

.logos-slide img {
  /* height: 50px; */

}

.container {
  position: relative;
  height: 100vh;
  transform-style: flat;
}

.box-one {
  position: absolute;
  top: calc(50% - 25px);
  left: calc(50% - 25px);
  height: 50px;
  width: 70px;
  height: 60px;
  animation: anim 2s ease-in-out infinite;
}
select
/* styles.css */
.truncate-two-lines {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.truncate-three-lines {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

@keyframes anim {
  0% {
    transform: perspective(100px) rotate(0deg);
  }

  50% {
    transform: perspective(100px) rotateX(-180deg) rotateY(0deg);
  }

  100% {
    transform: perspective(100px) rotateX(-180deg) rotateY(-180deg);
  }
}

/* .slick-slide {
  transition: transform 0.3s ease;
}

.slick-center .slide {
  position: relative;
  right: 100%;
  transform: scale(1.1); 
}

.slick-slide:not(.slick-center) .slide {
  position: relative;
  left: 100%;
  transform: scale(0.8); 
}


.slick-slider {
  margin: 0 auto;
} */
/* bg video */
.background-video-container {
  position: relative;
  width: 100%;
  /* Adjust the width as needed */
  height: 40px;
  /* Fixed height */
  overflow: hidden;
  /* Ensure the video does not overflow */
}

.background-video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  /* Ensure the video covers the container */
  z-index: -1;
  /* Ensure the video stays in the background */
}

.content {
  position: relative;
  z-index: 1;
  /* Ensure the content appears above the video */
  color: white;
  /* Optional: make text readable over the video */
  text-align: center;
  padding: 10px;
}

.fixed-background {
  overflow: hidden;
}
/* DatePicker  */
.inputboxDate{
  position: relative;
  width:100%;
  /* margin-bottom: 20px; */
}
.inputboxDate input{
  position: relative;
  /* border: #4ab0b0 1px solid; */
  /* border-radius: 6px; */
  width: 100%;
  padding: 10px 10px 10px;
  background-color: transparent;
  outline: none;
  color: #f1f1f1;
  font-weight: 600;
  font-size: 1rem;
  letter-spacing: 0.07em;
  z-index: 10;
  font-size: 16px;
}
.inputboxDatehome{
  position: relative;
  width: 100%;
  background-color: #ffffff;
  outline: none;
  font-weight: 500;
  font-size: 18px;
  letter-spacing: 0.01em;
  z-index: 10;
}
/* input  */
.inputbox{
  position: relative;
  width:100%;
  margin-bottom: 15px;
}

.inputbox input{
  position: relative;
  border: #4ab0b0 1px solid;
  border-radius: 6px;
  width: 100%;
  padding: 10px 10px 10px;
  background-color: transparent;
  outline: none;
  color: #f1f1f1;
  font-weight: 600;
  font-size: 1rem;
  letter-spacing: 0.07em;
  z-index: 10;
  font-size: 16px;
}
.inputbox .DatePicker{
  position: relative;
  border: #0a1919 1px solid;
  border-radius: 6px;
  width: 100%;
  padding: 10px 10px 10px;
  background-color: transparent;
  outline: none;
  color: #f1f1f1;
  font-weight: 600;
  font-size: 1rem;
  letter-spacing: 0.07em;
  z-index: 10;
  font-size: 16px;
}
textarea{
  outline: none;
  color: #f1f1f1;
  font-size: 1rem;
  letter-spacing: 0.05em;
}
.inputbox span{
  position: absolute;
  left: 0;
  padding: 10px 10px 10px;
  font-size: 1rem;
  color: #f1f1f1;
  pointer-events: none;
  letter-spacing: 0.05em;
  transition: 0.5s;
  z-index: 20;
}
.inputbox input:valid ~ span,
.inputbox input:focus ~ span
{
  color:#f1f1f1;
  font-size: 0.75em;
  transform: translateX(-10px) translateY(-30px);
}
.inputbox .DatePicker:valid ~ span,
.inputbox .DatePicker:focus ~ span
{
  color:#f1f1f1;
  font-size: 0.75em;
  transform: translateX(-10px) translateY(-30px);
}
.inputbox i{
  position: absolute;
  left: 0;bottom: 0;
  width: 100%;
  height: 0px;
  border-radius: 6px;
  background: #05273e;
  transition: 0.5s;
  pointer-events: none;
  z-index: 9;
}
.inputbox input:valid ~ i,
.inputbox input:focus ~ i{
  height: 45px;
}
.inputbox .DatePicker:valid ~ i,
.inputbox .DatePicker:focus ~ i{
  height: 45px;
}
.inputbox .select-wrapper {
  position: relative;
  width: 100%;
}

.inputbox .custom-select {
  position: relative;
  border: #4ab0b0 1px solid;
  border-radius: 6px;
  width: 100%;
   /* Default height */
  background: transparent;
  outline: none;
  color: #ffffff;
  font-weight: 600;
  font-size: 16px;
  padding: 10px;
  appearance: none; /* Remove default styling */
  background-color: transparent;
  z-index: 10;
  transition: background-color 0.5s;
}

.inputbox .custom-select:focus,
.inputbox .custom-select:valid {
  background-color: #05273e; /* Change background on focus/valid */
}

.inputbox .placeholder {
  position: absolute;
  top: 50%;
  /* left: 10px; */
  padding: 10px;
  font-size: 16px;
  color: #234971;
  pointer-events: none;
  transform: translateY(-40%);
  transition: 0.5s;
  z-index: 20;
}

.inputbox .custom-select:focus ~ .placeholder,
.inputbox .custom-select:valid ~ .placeholder {
  color: #4ab0b0;
  font-size: 12px;
  transform: translateY(-150%);
}

.inputbox .highlight {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 0px;
  border-radius: 6px;
  background: #05273e;
  transition: 0.5s;
  pointer-events: none;
  z-index: 9;
}

.inputbox .custom-select:focus ~ .highlight,
.inputbox .custom-select:valid ~ .highlight {
  height: 45px;
}


/* 
.date-picker-container {
  margin: 1rem 0;
}

.date-picker-input {
  width: 100%;
  border: 1px solid #ccc;
  padding: 0.5rem;
  border-radius: 4px;
  font-size: 16px;
  background-color: #f9f9f9;
}

.selected-date {
  margin-top: 0.5rem;
  font-size: 14px;
  color: #333;
} */
 /* triangle  */
.triangle {
  width: 0;
  height: 0;
  border-left: 20px solid transparent;
  border-right: 20px solid transparent;
  border-bottom: 20px solid white; /* Triangle color */
}


/* Custom CSS for react-datepicker */

.react-datepicker__header {
  background-color: #234971; /* Change the header background color */
  color: rgb(18, 115, 144) !important; /* Change the header text color */
}

.react-datepicker__day {
  border-radius: 50%; /* Change the border radius for day cells */
}

.react-datepicker__day:hover {
  background-color: #3ca02e; /* Change the hover background color */
}

.react-datepicker__time-container {
  /* background-color: #ae5555; Change the background color of time picker */
}

.react-datepicker__time-picker {
  color: #ffffff;
  border: 1px solid #234971; /* Change the border color of time picker */
}

.react-datepicker__time-list-item {
  padding-top: 10px !important;
  color: #ffffff;
  background-color: #15617b /* Change the background color of time list items */
}

.react-datepicker__time-list-item:hover {
  background-color: #334661 !important;
}

.react-datepicker__month {
  margin: 0; /* Remove margin from month view */
}

.react-datepicker__month-text {
  color: #234971; /* Change the color of month text */
}
/* Custom CSS for react-datepicker popup */

.react-datepicker {
  width: 327px !important; /* Set fixed width to 500px */
}

.react-datepicker__triangle {
  display: none; /* Optionally hide the triangle indicator if not needed */
}

.react-datepicker__header {
  background-color: #234971; /* Example: Change header background color */
  color: white; /* Example: Change header text color */
}

.react-datepicker__time-container {
  width: 500px; /* Ensure time container matches the width */
}

/* Responsive adjustments (optional) */
@media (max-width: 768px) {
  .react-datepicker {
    width: 100% !important; /* Adjust width for smaller screens */
    max-width: 500px; /* Ensure it does not exceed 500px */
  }
}

@media (max-width: 480px) {
  .react-datepicker {
    width: 100% !important; /* Further adjust width for very small screens */
    max-width: 500px; /* Ensure it does not exceed 500px */
  }
}


/* phone number country code  */
/* Custom styles for react-phone-number-input */
.PhoneInput {
  width: 100%;
}

.PhoneInputInput {
  outline: none;
  border-radius: 5px;
  padding:0 10px;
}
 
    

.rich-content b, .rich-content strong {
  color: #a3d284; /* Bold text in green */
}

.rich-content {
  color: #234971; /* Default text in blue */
}



.blog b, .blog strong {
  color: #a3d284; /* Bold text in green */
}

.blog {
  color: #234971; /* Default text in blue */
}


.team b, .team strong {
  color: #a3d284; /* Bold text in green */
}

.team {
  color: #234971; /* Default text in blue */
}



.doctor b, .doctor strong {
  color: #a3d284; /* Bold text in green */
}

.doctor {
  color: #234971; /* Default text in blue */
}


.select__control {
  padding-left: 5px;
}

.flag-img {
  margin-left: 5px; 
}



/* Add this to your CSS file */
.custom-phone-input .PhoneInputCountrySelect {
  background-color: #ffffff; /* Change to your preferred color */
  color: black; /* Optional: Change the text color */
}

.custom-phone-input .PhoneInputCountrySelect:focus {
  outline: none;
}


.custom-datepicker {
  z-index: 1000 !important; /* Ensure the dropdown is above other elements */
}

/* galrey  */
.lightbox {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.lightbox-content {
  position: relative;
}

.full-image {
  max-width: 90vw;
  max-height: 80vh;
  border-radius: 8px;
}

.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background: #fff;
  color: #333;
  border: none;
  padding: 5px;
  cursor: pointer;
  border-radius: 50%;
  font-size: 16px;
}



.ql-indent-2 {
  padding-left: 20px;  /* Adjust the padding for indentation */
  list-style-position: inside;  /* Ensures the bullet is moved with the text */
}

.ql-indent-1 {
  padding-left: 5px;  /* Adjust the padding for indentation */
  list-style-position: inside;  /* Ensures the bullet is moved with the text */
}

.ql-editor {
  letter-spacing: 5px; /* Adjust the space between characters */
}
ul {
  padding-left: 0;
  margin-left: 0;
}


